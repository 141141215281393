import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { onSearch, onSearchReset } from 'redux/actions/ticket.actions';

import Modal from 'components/commons/Modal';
import SearchTicketForm from 'components/forms/SearchTicketForm';

const SearchTicketContainer = (props) => {
  const {
    item, loading, onClose, onSearchAction, onSearchResetAction,
  } = props;

  useEffect(() => {
    onSearchResetAction();
  }, []);

  const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const onSubmit = ({ search }) => {
    if (!search.trim()) return;
    const value = removeAccents(search);
    onSearchAction(value);
  };

  return (
    <Modal loading={loading} onClose={onClose}>
      <SearchTicketForm
        item={item}
        loading={loading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  item: state.ticket.selected,
  loading: state.ticket.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onSearchAction: bindActionCreators(onSearch, dispatch),
  onSearchResetAction: bindActionCreators(onSearchReset, dispatch),
});

SearchTicketContainer.propTypes = {
  item: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearchAction: PropTypes.func.isRequired,
  onSearchResetAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTicketContainer);
