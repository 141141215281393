import React from 'react';
import PropTypes from 'prop-types';

import QuantitySelector from 'components/commons/QuantitySelector';

import './DescriptionItem.scss';

const DescriptionItem = (props) => {
  const {
    description, onQuantityChange, showQuantitySelector, storage, othersWholesale,
    storageSub, othersWholesaleSub,
  } = props;
  const quantity = storage ? storage.quantity : (storageSub ? storageSub.quantity : 0);
  const quantityOtherWholesale = othersWholesale.length > 0 ? othersWholesale.map((otherProduct) => (otherProduct.quantity))
    : (othersWholesaleSub.length > 0 ? othersWholesaleSub.map((otherProduct) => (otherProduct.quantity)) : [0]);
  return description.stock_descriptions.stock_category_id === 1 && description.stock_descriptions.rgb ? (
    <>
      <div className="color-container">
        <span className="color-circle" style={{ backgroundColor: description.stock_descriptions.rgb }} />
        <span className="color-name">{description.stock_descriptions.name}</span>
      </div>
      {showQuantitySelector && (
        <QuantitySelector
          limit={description.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
          description={description}
          quantityOthersWholesale={quantityOtherWholesale}
        />
      )}
    </>
  ) : (
    <div className="d-flex flex-column align-items-center">
      <span className="description-name">{description.stock_descriptions.name}</span>
      <QuantitySelector
        limit={description.stock}
        onQuantityChange={onQuantityChange}
        quantity={quantity}
        description={description}
        quantityOthersWholesale={quantityOtherWholesale}
      />
    </div>
  );
};

DescriptionItem.defaultProps = {
  showQuantitySelector: false,
  storage: null,
  storageSub: null,
  othersWholesale: [],
  othersWholesaleSub: [],
};

DescriptionItem.propTypes = {
  description: PropTypes.object.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  showQuantitySelector: PropTypes.bool,
  storage: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  storageSub: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  /*othersWholesale: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  othersWholesaleSub: PropTypes.shape({
    quantity: PropTypes.number,
  }),*/
};

export default DescriptionItem;
