import * as constants from 'redux/constants/ticket.constants';

const defaultState = {
  loading: false,
  selected: null,
};

const ticketReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.TICKET_ON_SEARCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.TICKET_ON_SEARCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selected: data.item,
      };
    case constants.TICKET_ON_SEARCH_RESET:
      return {
        ...state,
        selected: null,
      };
    case constants.TICKET_ON_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ticketReducer;
