import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/ticket.constants';
import { search } from 'services/ticket.services';

export function* ticketSearch(action) {
  try {
    const data = yield call(search, action.number);
    yield put({ type: constants.TICKET_ON_SEARCH_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.TICKET_ON_SEARCH_FAILED, error });
  }
}

export function* watchTickets() {
  yield all([
    takeLatest(constants.TICKET_ON_SEARCH_REQUESTED, ticketSearch),
  ]);
}
