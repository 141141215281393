import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import PropTypes from 'prop-types';
import downloadImage from './assets/download.svg';
import downloadGreenImage from './assets/downloadGreen.svg';

import './Catalogue.scss';

const Catalogue = (props) => {
  const { catalogs } = props;
  const [catalogsState, setCatalogsState] = useState([]);

  useEffect(() => {
    if (catalogs.length > 0) {
      const initialCatalogsState = catalogs.map((catalog, index) => ({
        ...catalog,
        clicked: index === 0,
      }));
      setCatalogsState(initialCatalogsState);
    }
  }, [catalogs]);

  const handleLinkClick = (index) => {
    const updatedCatalogs = catalogsState.map((catalog, i) => ({
      ...catalog,
      clicked: i === index,
    }));
    setCatalogsState(updatedCatalogs);
  };
  return (
    <div className="container fix-space" data-testid="faq-page">
      <div className="row">
        <div className="col clearfix catalogue-container">
          { catalogs.length > 0
            ? (
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      {catalogsState.map((catalog, i) => (
                        <Nav.Item>
                          <Nav.Link eventKey={i} onClick={() => handleLinkClick(i)}>
                            {catalog.title}
                          </Nav.Link>
                          <a href={catalog.pdf} className="download-pdf" download target="_blank" rel="noreferrer">
                            <img src={catalog.clicked ? downloadImage : downloadGreenImage} alt="" />
                          </a>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      {catalogs.map((catalog, i) => (
                        <Tab.Pane eventKey={i}>
                          <iframe src={catalog.pdf} width="800" height="600" />
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )
            : <h2> No hay catalogos disponibles</h2>}
        </div>
      </div>
    </div>
  );
};
Catalogue.propTypes = {
  catalogs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};
export default Catalogue;
