import * as constants from 'redux/constants/ticket.constants';

export const onSearch = (number) => ({
  type: constants.TICKET_ON_SEARCH_REQUESTED,
  number,
});

export const onSearchReset = () => ({
  type: constants.TICKET_ON_SEARCH_RESET,
});
