import { createSelector } from 'reselect';
import { getProducts } from 'redux/selectors/product.selector';

const getCartItems = (state) => state.cart.items;
const getCartItemsSub = (state) => state.cart.itemsSub;

const getItem = (state, props) => props.item;

export const itemQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.find((cartItem) => cartItem.itemId === item.id, null)
  ));

export const itemsQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.filter((cartItem) => cartItem.itemId === item.id, null)
  ));

export const itemsSubQuantitySelector = createSelector([getCartItemsSub, getItem],
  (cartItems, item) => (
    cartItems.filter((cartItem) => cartItem.itemId === item.id, null)
  ));
export const cartSizeSelector = createSelector([getCartItems], (items) => (
  items.reduce((a, b) => a + b.quantity, 0)
));

export const cartItemsSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const items = {};
    cartItems.forEach(({
      itemId, quantity, descriptionId, wholesale,
    }) => {
      const item = products.find((product) => product.id === itemId, null);
      if (item) {
        if (item.stock_product_descriptions.length > 0) {
          const colors = item.stock_product_descriptions.filter(
            ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
          );
          let cartItem = items[itemId];
          if (!cartItem) {
            cartItem = {
              item,
              itemId,
              quantity,
              colors,
              subItems: [],
              subItemsDescriptions: {},
            };
          }
          const description = item.stock_product_descriptions.find(
            ({ id }) => id === descriptionId,
          );
          const subItemsDescriptions = cartItems.find((data) => (data.descriptionId === descriptionId
            && data.wholesale?.id === wholesale?.id));
          const othersSubItemsWholesale = cartItems.filter((data) => (data.descriptionId === descriptionId
              && data.wholesale?.id !== wholesale?.id));
          subItemsDescriptions.quantity = quantity;
          subItemsDescriptions.description = description;
          const quantitiesAndDescriptions = othersSubItemsWholesale.map((otherProduct) => ({
            quantity: otherProduct.quantity,
            description: otherProduct.description,
            wholesale: otherProduct.wholesale,
          }));
          subItemsDescriptions.othersSubItemsWholesale = quantitiesAndDescriptions;

          if (!cartItem.subItemsDescriptions[wholesale?.id]) {
            cartItem.subItemsDescriptions[wholesale?.id] = {};
          }
          if (!cartItem.subItemsDescriptions[wholesale?.id][descriptionId]) {
            cartItem.subItemsDescriptions[wholesale?.id][descriptionId] = [];
          }
          cartItem.subItemsDescriptions[wholesale?.id][descriptionId].push(subItemsDescriptions);
          items[itemId] = cartItem;
        } else if (item.product_wholesaler.length > 0) {
          let cartItem = items[itemId];
          if (!cartItem) {
            cartItem = {
              item,
              itemId,
              quantity,
              colors: undefined,
              subItems: [],
              subItemsDescriptions: undefined,
            };
          }
          const subItems = cartItems.find((data) => (data.itemId === itemId
             && data.wholesale?.id === wholesale?.id));
          subItems.quantity = quantity;
          cartItem.subItems = [...cartItem.subItems, subItems];

          items[itemId] = cartItem;
        } else {
          items[itemId] = {
            item,
            itemId,
            quantity,
            subItems: undefined,
            colors: undefined,
            subItemsDescriptions: undefined,
          };
        }
      }
    });
    const array = Object.values(items).map(({
      item, quantity, colors, subItems,
      subItemsDescriptions,
    }) => {
      if ((colors && !Object.values(subItemsDescriptions).every((value) => Array.isArray(value)
      && value.length === 0))) {
        const cartQuantity = Object.values(subItemsDescriptions)
          .map((obj) => obj.quantity)
          .reduce((total, q) => total + q, 0);

        const combinations = colors?.reduce((a, b) => {
          a[b.stock_description_id] = a[b.stock_description_id] || {};
          const combination1 = item?.stock_product_descriptions
            ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
            ?.find(({ combination }) => combination === b.combination);
          const combination2 = item?.stock_product_descriptions
            ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id === 1)
            ?.find(({ combination }) => combination === b.combination);
          const descriptionId = (combination1 || combination2)?.id;

          Object.entries(subItemsDescriptions).forEach(([wholesaleId, subItem]) => {
            if (subItem[descriptionId] && Array.isArray(subItem[descriptionId])) {
              a[wholesaleId] = a[wholesaleId] || {};
              a[wholesaleId][b.stock_description_id] = a[wholesaleId][b.stock_description_id] || [];
              a[wholesaleId][b.stock_description_id].push(...subItem[descriptionId]);
            } else if (!a[wholesaleId]) {
              a[wholesaleId] = {};
            }
          });

          return a;
        }, Object.create(null));
        return {
          item,
          itemId: item.id,
          quantity: cartQuantity,
          colors,
          subItems: undefined,
          subItemsDescriptions: combinations,
        };
      }
      return {
        item,
        itemId: item.id,
        quantity,
        colors,
        subItems,
        subItemsDescriptions,
      };
    });
    return array;
  });

export const cartSubtotalSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const total = cartItems.reduce((a, b) => {
      const cartItem = products.find((product) => product.id === b.itemId, null);
      let price = 0;
      if (cartItem) {
        if (!b.wholesale.bool && cartItem.product_wholesaler.length === 0) {
          price = cartItem.sale ? cartItem.sale * b.quantity : cartItem.price * b.quantity;
        } else if (b.wholesale.bool && (cartItem.product_wholesaler.length > 0)) {
          const subItem = cartItem.product_wholesaler.find((prod) => prod.id === b.wholesale.id);
          price = subItem.price * b.quantity;
        } else if (!b.wholesale.bool && (cartItem.product_wholesaler.length > 0)) {
          price = cartItem.price * b.quantity;
        }
      }
      return a + price;
    }, 0);
    return total;
  });
