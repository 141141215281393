import React from 'react';
import ScrollMenu from 'react-horizontal-scroll-menu';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { categoriesArrayPropTypes } from 'helpers/propTypes';

import Menu from 'components/main/Header/Menu';
import Logo from 'components/main/Header/Logo';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    cartSize, categories, closeMenu, isMenuActive, isUserLoggedIn, openMenu, openSearchModal,
    openSearchTicketModal,
  } = props;

  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={closeMenu}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`}>
        <div
          className="hamburger-close"
          role="presentation"
          onClick={closeMenu}
        >
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={closeMenu}
            isMobile
            isUserLoggedIn={isUserLoggedIn}
            ulClassName="menu-top-nav"
          />
        </div>
      </div>
    </>
  );

  return (
    <div data-testid="navbar">
      <div className="main-nav-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <div className="navbar-content">
                  <div
                    className="hamburger-container"
                    role="presentation"
                    onClick={openMenu}
                  >
                    <i className="fa fa-bars" aria-hidden="true" />
                  </div>
                  <Logo />
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                  />
                  <ul className="navbar-user">
                    <li>
                      <div className="navbar-icon" role="presentation" onClick={openSearchModal}>
                        <i className="fa fa-search" aria-hidden="true" />
                      </div>
                    </li>
                    <li>
                      <div className="navbar-icon" role="presentation" onClick={openSearchTicketModal}>
                        <i className="fa fa-file-text-o" aria-hidden="true" />
                      </div>
                    </li>
                    <li>
                      <Link to="/carrito">
                        <div className="navbar-icon">
                          <i className="fa fa-shopping-cart" aria-hidden="true" />
                          <span id="checkout-items" className="checkout-items">
                            {cartSize}
                          </span>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="navbar-categories">
          <ScrollMenu
            alignCenter={false}
            arrowDisabledClass="scroll-menu-arrow--disabled"
            arrowLeft={<span className="arrow-prev">{' < '}</span>}
            arrowRight={<span className="arrow-next">{' > '}</span>}
            data={categories.map((category) => (
              <Link key={category.id} to={`/productos/${category.code}`}>
                {category.name}
              </Link>
            ))}
            dragging={false}
            hideArrows
            hideSingleArrow
            wheel={false}
          />
        </div>
      </div>
      {renderResponsiveMenu()}
    </div>
  );
};

Navbar.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearchModal: PropTypes.func.isRequired,
  openSearchTicketModal: PropTypes.func.isRequired,
};

export default Navbar;
