export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const MODAL_PRODUCT = 'MODAL_PRODUCT';
export const MODAL_LOGIN = 'MODAL_LOGIN';
export const MODAL_REGISTER = 'MODAL_REGISTER';
export const MODAL_RESET_PASSWORD = 'MODAL_RESET_PASSWORD';
export const MODAL_SEARCH = 'MODAL_SEARCH';
export const MODAL_SEARCH_TICKET = 'MODAL_SEARCH_TICKET';
export const MODAL_SECURE_CHECKOUT = 'MODAL_SECURE_CHECKOUT';
