import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification } from 'helpers/notifications';
import { updateCart } from 'redux/actions/cart.actions';
import { itemsQuantitySelector, itemsSubQuantitySelector } from 'redux/selectors/cart.selector';
import { productImagesSelector } from 'redux/selectors/product.selector';

import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    images, item, onClose, updateCartAction, cartItemDescriptions, cartItemSubDescriptions
  } = props;
  const singleProductUrl = `/producto/${item.code.replaceAll('%', '')}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);
  const [wholesale, setWholesale] = useState({ id: 0, bool: false, quantity: 0 });
  const productCart = cartItemDescriptions.find((data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)));
  const productCartSub = cartItemSubDescriptions.find((data) => ((data.wholesale.bool === wholesale.bool) && (data.wholesale.id === wholesale.id)));
  const colors = item?.stock_product_descriptions?.filter(
    ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
  );
  const combinations = colors?.reduce((a, b) => {
    a[b.stock_description_id] = a[b.stock_description_id] || [];
    const combination = item?.stock_product_descriptions
      ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
      ?.find(
        (stock_product_description) => stock_product_description.combination === b.combination,
      );
    if (combination) a[b.stock_description_id].push(combination);
    return a;
  }, Object.create(null));

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description) => {
    if (description) {
      updateCartAction(item.id, value, description.id, wholesale);
    } else {
      updateCartAction(item.id, value, null, wholesale);
    }
  };

  return (
    <Product
      images={images}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      productCart={productCart}
      productCartSub={productCartSub}
      cartItemDescriptions={cartItemDescriptions}
      cartItemSubDescriptions={cartItemSubDescriptions}
      singleProductFullUrl={singleProductFullUrl}
      colors={colors}
      wholesale={wholesale}
      setWholesale={setWholesale}
      combinations={combinations}
    />
  );
};

const mapStateToProps = (state, props) => ({
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
  cartItemSubDescriptions: itemsSubQuantitySelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ProductContainer.defaultProps = {
  cartItemDescriptions: null,
  cartItemSubDescriptions: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItemDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  cartItemSubDescriptions: PropTypes.arrayOf(PropTypes.shape({
    descriptionId: PropTypes.number,
  })),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
