import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

const Footer = () => (
  <footer className="footer" data-testid="footer">
    <div className="container">
      <div className="row footer-top">
        <div className="col-lg-5 col-md-12 footer-column">
          <span className="title">Cont&aacute;ctanos</span>
          <ul>
            <li>COLÓN 580, SAN FERNANDO, BS. AS. / OLAYA 1739, CABA</li>
            <li>Argentina</li>
            <li>Tel&eacute;fono: </li>
            <li>
              <a href="mailto:dan21198@gmail.com">
              dan21198@gmail.com
              </a>
            </li>
            <li><Link to="/contacto">Ver mapa</Link></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 footer-column">
          <span className="title">Informaci&oacute;n</span>
          <ul>
            <li><Link to="/servicios">Servicios</Link></li>
            <li><Link to="/contacto">Contacto</Link></li>
          </ul>
        </div>
        <div className="col-lg-5 col-md-6 footer-column social-links">
          <a href="https://www.facebook.com/#" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook" />
          </a>
          <a href="https://instagram.com/#?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram" />
          </a>
        </div>
      </div>
      <div className="copyright-wrap d-md-flex py-4">
        <div className="copyright">
          &copy; 2024 Derechos reservados por
          {' '}
          <a href="http://jaypionba.com/" target="_blank" rel="noopener noreferrer">Jaypion</a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
