import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { productPropTypes } from 'helpers/propTypes';
import { itemQuantitySelector } from 'redux/selectors/cart.selector';
import { openProductModal } from 'redux/actions/modal.actions';
import { updateCart } from 'redux/actions/cart.actions';

import Item from 'components/commons/Item';

const ItemContainer = (props) => {
  const {
    cartItem, item, openProductModalAction, updateCartAction,
  } = props;

  const quantity = cartItem ? cartItem.quantity : 0;

  const onQuantityChange = (value) => {
    if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, {
        id: 0, bool: false, quantity: 0,
      });
    }
  };

  return (
    <Item
      item={item}
      onItemClick={() => openProductModalAction(item)}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ItemContainer.defaultProps = {
  cartItem: null,
};

ItemContainer.propTypes = {
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
