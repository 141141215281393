import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import PropTypes from 'prop-types';
import downloadImage from './assets/download.svg';
import downloadGreenImage from './assets/downloadGreen.svg';

import './Prices.scss';

const Prices = (props) => {
  const { prices } = props;
  const [pricesState, setPricesState] = useState([]);

  useEffect(() => {
    if (prices.length > 0) {
      const initialPricesState = prices.map((price, index) => ({
        ...price,
        clicked: index === 0,
      }));
      setPricesState(initialPricesState);
    }
  }, [prices]);

  const handleLinkClick = (index) => {
    const updatedPrices = pricesState.map((price, i) => ({
      ...price,
      clicked: i === index,
    }));
    setPricesState(updatedPrices);
  };
  return (
    <div className="container fix-space" data-testid="faq-page">
      <div className="row">
        <div className="col clearfix catalogue-container">
          { prices.length > 0
            ? (
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                      {pricesState.map((price, i) => (
                        <Nav.Item>
                          <Nav.Link eventKey={i} onClick={() => handleLinkClick(i)}>
                            {price.title}
                          </Nav.Link>
                          <a href={price.pdf} className="download-pdf" download target="_blank" rel="noreferrer">
                            <img src={price.clicked ? downloadImage : downloadGreenImage} alt="" />
                          </a>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content>
                      {prices.map((price, i) => (
                        <Tab.Pane eventKey={i}>
                          <iframe src={price.pdf} width="800" height="600" />
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )
            : <h2> No hay precios disponibles</h2>}
        </div>
      </div>
    </div>
  );
};
Prices.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    pdf: PropTypes.string,
  })).isRequired,
};
export default Prices;
