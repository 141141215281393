import React from 'react';

import Breadcrumb from 'components/commons/Breadcrumb';

import corteImage from './assets/corte.jpg';
import optimizacionImage from './assets/optimizacion.jpg';
import perforadoImage from './assets/perforado.jpg';
import tapacantosImage from './assets/tapacantos.png';
import pantografiadoImage from './assets/pantografiado.png';
import pantografiado1Image from './assets/pantografiado-1.png';
import furniture18Image from './assets/furniture-18.jpg';
import furniture19Image from './assets/furniture-19.jpg';
import serviciosPantografiadoImage from './assets/servicios_a_pantografiado.jpg';
import serviciosColocacionImage from './assets/servicios_a_colocacion.jpg';
import serviciosCortesImage from './assets/servicios_a_cortes.jpg';

import './Services.scss';

const Services = () => (
  <div className="container" data-testid="services-page">
    <div className="row">
      <div className="col clearfix">
        <Breadcrumb urls={['/servicios']} names={['Servicios']} />
        <h2>Soñás con algo personalizado?</h2>
        <h2>Nosotros lo hacemos realidad</h2>
        <div className="row services-container">
          <div className="col-md-4 service">
            <img alt="Corte" src={corteImage} />
            <span>CORTES INDUSTRIALES ESCUADRADOS CON LA MAS ABSOLUTA PRECISIÓN</span>
          </div>
          <div className="col-md-4 service">
            <img alt="Optimizacion" src={optimizacionImage} />
            <span>SISTEMA COMPUTARIZADO QUE ASEGURA EL MEJOR APROVECHAMIENTO DEL MATERIAL</span>
          </div>
          <div className="col-md-4 service">
            <img alt="Perforado" src={perforadoImage} />
            <span>OBTENGA SUS PIEZAS PERFORADAS A ESCALA INDUSTRIAL LISTAS PARA ENSAMBLAR</span>
          </div>
          <div className="col-md-4 service">
            <img alt="Tapacantos" src={tapacantosImage} />
            <span>VARIEDAD EN TAPACANTOS, MELAMINICOS, PVC, ABS, ALUMINIO, SERVICIO DE PEGADO Y GRAN VARIEDAD EN PRENCOLADOS</span>
          </div>
          <div className="col-md-4 service">
            <img alt="Pantografiado" src={pantografiadoImage} />
            <span>LAS PIEZAS PUEDEN TOMAR VARIADAS FORMAS YA QUE AHORA ES POSIBLE REALIZAR CUALQUIER MODELO</span>
          </div>
          <div className="col-md-4 service">
            <img alt="Pantografiado-1" src={pantografiado1Image} />
            <span>PERFORACIONES DE BISAGRAS, UN SERVICIO MÁS QUE OFRECEMOS PARA SU COMODIDAD</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 d-flex">
            <img alt="furniture-18" className="furniture-img" src={furniture18Image} />
          </div>
          <div className="col-md-4 d-flex flex-column">
            <p>En Madergold pensamos en Usted. Por eso le ofrecemos soluciones prácticas para que pueda construir todo lo que se imagina sin complicaciones. Excelentes servicios, con excelentes resultados.</p>
            <br />
            <img alt="furniture-19" className="furniture-img" src={furniture19Image} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 d-flex">
            <img alt="servicios-pantografiado" className="furniture-img" src={serviciosPantografiadoImage} />
          </div>
          <div className="col-md-4 d-flex">
            <img alt="servicios-colocacion" className="furniture-img" src={serviciosColocacionImage} />
          </div>
          <div className="col-md-4 d-flex">
            <img alt="servicios-corte" className="furniture-img" src={serviciosCortesImage} />
          </div>
        </div>
        <div className="row sells">
          <h3>
            Nos destacamos por las
            {' '}
            <strong>ventas mayoristas</strong>
            {' '}
            gracias a nuestro amplio catálogo y stock permanente.
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default Services;
