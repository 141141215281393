import * as constants from 'redux/constants/cart.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  items: [],
  itemsSub: [],
  loading: false,
  coupon: null,
};

const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.CART_CHECKOUT_DELIVERY_REQUESTED:
    case constants.CART_CHECKOUT_PICKUP_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.CART_UPDATE_SUCCEEDED:
      return {
        ...state,
        items: action.items,
        itemsSub: action.itemsSub,
      };
    case constants.CART_COUPON_SUCCEEDED:
      return {
        ...state,
        coupon: action.data.coupon,
      };
    case constants.CART_COUPON_DELETE:
    case constants.CART_COUPON_FAILED:
      return {
        ...state,
        coupon: null,
      };
    case constants.CART_CHECKOUT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.CART_CHECKOUT_SUCCEEDED:
    case userConstants.USER_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default cartReducer;
