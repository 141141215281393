import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { categoriesArrayPropTypes, userPropTypes } from 'helpers/propTypes';
import { logout } from 'redux/actions/user.actions';
import {
  openLoginModal, openRegisterModal, openSearchModal, openSearchTicketModal,
} from 'redux/actions/modal.actions';
import { cartSizeSelector } from 'redux/selectors/cart.selector';

import TopNav from 'components/main/Header/TopNav';
import Navbar from 'components/main/Header/Navbar';

const HeaderContainer = (props) => {
  const {
    categories, cartSize, logoutAction, openLoginModalAction,
    openRegisterModalAction, openSearchModalAction, openSearchTicketModalAction, user,
  } = props;
  const [isMenuActive, setIsMenuActive] = useState(false);

  return (
    <header className="header trans-300">
      <TopNav
        logout={logoutAction}
        openLoginModal={openLoginModalAction}
        openRegisterModal={openRegisterModalAction}
        user={user}
      />
      <Navbar
        cartSize={cartSize}
        categories={categories}
        closeMenu={() => setIsMenuActive(false)}
        isMenuActive={isMenuActive}
        isUserLoggedIn={!!user}
        openMenu={() => setIsMenuActive(true)}
        openSearchModal={openSearchModalAction}
        openSearchTicketModal={openSearchTicketModalAction}
      />
    </header>
  );
};

const mapStateToProps = (state) => ({
  cartSize: cartSizeSelector(state),
  categories: state.category.items,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAction: bindActionCreators(logout, dispatch),
  openLoginModalAction: bindActionCreators(openLoginModal, dispatch),
  openRegisterModalAction: bindActionCreators(openRegisterModal, dispatch),
  openSearchModalAction: bindActionCreators(openSearchModal, dispatch),
  openSearchTicketModalAction: bindActionCreators(openSearchTicketModal, dispatch),
});

HeaderContainer.defaultProps = {
  user: null,
};

HeaderContainer.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  logoutAction: PropTypes.func.isRequired,
  openLoginModalAction: PropTypes.func.isRequired,
  openRegisterModalAction: PropTypes.func.isRequired,
  openSearchModalAction: PropTypes.func.isRequired,
  openSearchTicketModalAction: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
