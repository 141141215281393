import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import './SearchTicketForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.search || !values.search.trim()) {
    errors.search = 'Debes completar este campo';
  }
  return errors;
};

const SearchTicketForm = (props) => {
  const {
    handleSubmit, loading, item, onSubmit, reset, submitting,
  } = props;

  useEffect(() => {
    if (item) reset();
    return () => reset();
  }, [item]);

  return (
    <div className="container" data-testid="search-ticket-form">
      <div className="row">
        {loading && <Loading message="Cargando..." size={80} />}
        <div className={`form-container ${loading ? 'd-none' : ''}`}>
          <h4>Buscar factura</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Field
                component={Input}
                inputClassName="input-search"
                label="Buscar..."
                name="search"
              />
              <Button
                buttonClass="btn-search"
                disabled={submitting}
                onClick={handleSubmit(onSubmit)}
                text={<i className="fa fa-search" aria-hidden="true" />}
                type="button"
              />
            </div>
          </form>
          <div className="search-items">
            {!item ? (
              <span className="search-result">No se encontraron facturas</span>
            ) : (
              <span className="search-result">
                {item.number}
                {' '}
                -
                {' '}
                {item.status.replace('-', ' ')}
                {' '}
                -
                {' '}
                {new Date(item.created_at).toISOString().split('T')[0]}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SearchTicketForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  item: PropTypes.shape({
    created_at: PropTypes.string,
    number: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'searchTicket',
  validate,
})(SearchTicketForm);
